<template>
	<div class="home">
		<img alt="logo" src="../assets/logo1.jpg" style="width: 50px" />
		<p>自动登录中…</p>
		<p>如果您长时间看到这段文字，请联系网站维护者</p>
	</div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
	name: "Home",
	components: {},
	methods: {
		messageBox(msg, type) {
			this.$message[type](msg);
		},
	},
	beforeMount() {
		if (localStorage.getItem("token")) {
			axios.defaults.headers.common["Authorization"] = localStorage.getItem(
				"token"
			);
			axios({
				url: "/api/hello-user",
				method: "GET",
			})
				.then((res) => {
					console.log(res);
					if (
						String(window.location.href).indexOf(
							"/" + String(res.data.usertype)
						) < 0
					) {
						// console.log(String(this.$route.path));
						// console.log('/' + String(res.data.usertype));
						// console.log(String(this.$route.path).indexOf('/' + String(res.data.usertype)));
						this.$router.push("/" + String(res.data.usertype));
					}
				})
				.catch((err) => {
					this.messageBox(
						"获取用户信息失败， " + err?.response?.data?.Message ?? "网络断开或神秘错误",
						"error"
					);
					console.log("Hello-user Failed");
					if (err.response.status == "401" || err.response.status == "400") {
						localStorage.removeItem("token");
					}
					this.$router.push("/login");
				});
		} else {
			this.$router.push("/login");
		}
	},
};
</script>
